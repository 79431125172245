import React, { useState } from "react"
import ReactPlayer from "react-player"
import moment from "moment"
import { StationContext } from "../context/station-context"

function AudioPlayer() {
  const {
    isPlaying,
    setStreamProgress,
    // streamPercent,
    setStreamPercent,
    setDuration,
    length,
    setLength,
    setDurationSeconds,
    currentTrack,
    ref,
  } = React.useContext(StationContext)
  // const [isSafari, setIsSafari] = useState(false)
  const updateProgress = progress => {
    // // console.log("Progress", progress);
    var per = (progress.playedSeconds / length) * 100
    // // console.log("per", per);
    setStreamPercent(Math.floor(per))
    // setDuration(Math.floor(progress.loadedSeconds));
    setStreamProgress(Math.floor(Math.floor(progress.playedSeconds)))
  }

  const updateDuration = newDuration => {
    setLength(Math.floor(newDuration))
    setDuration(
      moment("2015-01-01")
        .startOf("day")
        .seconds(Math.floor(newDuration))
        .format("H:mm:ss")
    )
    setDurationSeconds(newDuration)
  }

  React.useEffect(() => {
    const initPage = async () => {
      const test = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      // await setIsSafari(test)
      // await setLoaded(true)
    }
    initPage()
  }, [])

  return currentTrack && currentTrack.url ? (
    <ReactPlayer
      ref={ref}
      progressInterval={50}
      onDuration={progress => updateDuration(progress)}
      playing={isPlaying}
      controls={false}
      height={0}
      width={0}
      onProgress={progress => updateProgress(progress)}
      url={currentTrack.url}
      config={{
        file: {
          forceAudio: true,
        },
      }}
    />
  ) : (
    <div></div>
  )
}

export default AudioPlayer
